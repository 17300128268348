import {useEffect} from 'react';
import api from "../../api/baseApi";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../hooks/reduxHooks";
import {setPhoneState, setRedirectUrl} from "../../store/slices/auth-slice";

interface RedirectUrlPageProps {
  template: string;
}

const RedirectUrlPage = ({ template }: RedirectUrlPageProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fetchPhoneNumberByUrl = async () => {
    try {
      const data = await api<{ phone: string; redirect_url: string }>(
        `customers-links/auth-data`,
        {
          method: "POST",
          data: { url: window.location.href },
          headers: {
            "secret-key": "7td-53f21d0m#om!vye*mrq7$w3f@e2p3#(4--*^p_#(zr+^rt",
          },
        }
      );

      dispatch(setPhoneState(data.phone));
      dispatch(setRedirectUrl(data.redirect_url));
    } catch (error) {
      navigate(`${template}`);
    }
  };

  const fetchUrl = async () => {
    const currentUrl = window.location.href;
    try {
      await api(`customers-links`, {
        method: "post",
        data: { url: currentUrl },
        headers: {
          "secret-key": "7td-53f21d0m#om!vye*mrq7$w3f@e2p3#(4--*^p_#(zr+^rt",
        },
      });

      navigate(`${template}/c`);
    } catch (error: any) {
      navigate(`${template}`);
    }
  };

  const getLastPathSegment = (path: string): string => {
    const lastSlashIndex = path.lastIndexOf("/");
    return path.substring(lastSlashIndex + 1);
  };
  
  useEffect(() => {
    const path = window.location.pathname;
    
    const trimmedPath = getLastPathSegment(path);
    console.log({ trimmedPath });

    localStorage.setItem("redirectFromUrl", trimmedPath);
    fetchPhoneNumberByUrl();
    fetchUrl();
    //eslint-disable-next-line
  }, []);

  return <div></div>;
};

export default RedirectUrlPage;