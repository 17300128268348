import { useEffect, useState } from "react";
import api from "../../../api/baseApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setCustomer } from "../../../store/slices/customer-slice";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import { ICustomer, ICustomersResponse } from "../../../common/types/customer";
import CustomersList from "./CustomersList";
import { toast } from "react-toastify";
import CustomerSceleton from "../../../components/sceletons/CustomerSceleton";
import ConfirmPopup from "../../../components/popups/ConfirmPopup";
import CustomerSettings from "../../../components/popups/CustomerSettingsPopup";
import Search from "../../../components/Search";
import { useURLParams } from "../../../common/util/saveSearchParams";

export default function CustomersPage() {
  const dispatch = useAppDispatch();
  const { search, setSearch } = useURLParams();
  const customersData = useAppSelector((state) => state.customer);

  const [page, setPage] = useState(1);
  const limit = 20;

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
  const [customerToDelete, setCustomerToDelete] = useState<string | null>(null);

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const handleSearch = (value: string) => {
    if (search !== value) {
      setSearch(value);
    } else {
      setSearch(value);
    }
  };

  const fetchCustomers = async (page: number, search?:string) => {
    try {
      setLoading(true);
      const customers: ICustomersResponse = await api(
        `/customers`,
        {},
        {
          limit: `${limit}`,
          page: `${page}`,
          ...(search && { search })
        }
      );
      if (customers.customers) {
        dispatch(setCustomer(customers));
        setLoading(false);
      }
    } catch (error) {
      console.log("Error fetching customers", error);
      toast.error("Ошибка при загрузке клиентов");
    } finally {
      setLoading(false);
    }
  };

  const clearCache = async () => {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );
    } catch (error) {
      console.error("Ошибка при очистке кэша:", error);
    }
  };

  const deleteCustomer = async (customerId: string) => {
    try {
      await clearCache();

      await api(`customers/dialog/${customerId}`, {
        method: "DELETE",
        headers: {
          credentials: "include",
        },
      });
      const newData: ICustomersResponse = {
        ...customersData,
        customers: customersData.customers.filter(
          (customer) => customer.tgId !== customerId
        ),
        totalPages: customersData.totalPages ?? 0,
      };
      dispatch(setCustomer(newData));
      toast.success("Клиент успешно удалён");

      fetchCustomers(page);
    } catch (error) {
      console.log("Error deleting customer", error);
      toast.error("Ошибка при удалении клиента");
    }
  };

  const changeAcess = async (customerId: string, ids:string[]) => {
    try {
      await api(`customers/customer-access/${customerId}`, {
        method: "PUT",
        headers: {
          credentials: "include",
        },
        data: { ids },
      });
      toast.success("Доступы успешно изменены");
      fetchCustomers(page);
    } catch (error) {
      console.log("Error deleting customer", error);
      toast.error("Ошибка при изменении доступов");
    }
  }

  const handleDeleteCustomer = (customerId: string) => {
    setCustomerToDelete(customerId);
    setShowConfirmPopup(true);
  };

  const handleConfirmDelete = () => {
    if (customerToDelete) {
      deleteCustomer(customerToDelete);
    }
    setShowConfirmPopup(false);
    setCustomerToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmPopup(false);
    setCustomerToDelete(null);
  };

  const onChangeSettings = (customer:ICustomer) => {
    setSelectedCustomer(customer);
    setSettingsPopup(true);
  }

  const onCancelSettings = () => {
    setSettingsPopup(false);
    setSelectedCustomer(null);
  }

  const onSaveSettings = async (customerId:string,ids:string[]) => {
    if (selectedCustomer) {
     await changeAcess(customerId, ids);
    }
    onCancelSettings()
  }

  useEffect(() => {
    fetchCustomers(page, search);
    //eslint-disable-next-line
  }, [page, search]);

  const sceletons = [...new Array(20)].map((_, index) => (
    <CustomerSceleton key={index} />
  ));

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        <div className="flex flex-col w-full max-h-[75vh] overflow-y-auto">
          <Search
            textPlaceholder={"Поиск кастомера..."}
            onSearch={(value) => handleSearch(value)}
            startValue={search}
          />
          {isLoading ? (
            <ul className={"flex flex-col gap-y-2 w-full"}>{sceletons}</ul>
          ) : (
            <>
              <CustomersList
                customers={customersData.customers}
                onDelete={handleDeleteCustomer}
                onChangeSettings={onChangeSettings}
              />
              <div className="flex justify-between mt-4">
                <button
                  onClick={handlePrevPage}
                  disabled={page === 1}
                  className="px-4 py-2 bg-gray-200 text-black rounded disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Prev Page
                </button>
                <span>
                  Page {page} of {customersData.totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={customersData.customers.length < limit}
                  className="px-4 py-2 bg-gray-200 text-black rounded disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Next Page
                </button>
              </div>
            </>
          )}
        </div>
      </CustomersInfoCard>
      {showConfirmPopup && (
        <ConfirmPopup
          message="Вы уверены, что хотите удалить этого клиента?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
      {settingsPopup && (
        <CustomerSettings
          customer={selectedCustomer}
          onSave={onSaveSettings}
          onCancel={onCancelSettings}
        />
      )}
    </ContentContainer>
  );
}
