
interface CodeSectionProps {
    phoneNumber: string;
    error:{error:boolean,message:string},
    code:string
    onChange:(value:string)=>void
    onSubmit:()=>void
}

export default function CodeSection({phoneNumber, code, onChange, onSubmit}: CodeSectionProps) {
  return (
    <>
      <p className="font-bold text-lg text-center mb-2">
        Номер телефона учётной записи:
        <br /> {phoneNumber}
      </p>
      <p className="font-bold text-md text-center mb-2">
        Для доступа к Личному кабинету
        <br /> подтвердите номер телефона
      </p>
      <p className="font-bold text-md text-center mb-4">Введите код:</p>
      <div className="flex flex-col items-center gap-y-4 w-2/3">
        <input
          type="text"
          autoFocus
          id="number"
          inputMode="tel"
          value={code}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          className="rounded-xl px-4 py-2 h-12 bg-white text-black w-full"
        />
        <button
          onClick={onSubmit}
          className="rounded-xl px-4 py-2 h-12 bg-[#21424a] w-full font-bold text-sm uppercase"
        >
          Подтвердить код
        </button>
      </div>
    </>
  );
}
