import { useEffect, useState } from "react";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import LinksDialogWindow from "./add-link-dialog-window/LinksDialogWindow";
import { CustomerLink } from "../../../common/types/customer-link";
import LinksTable from "./links-table/LinksTable";
import api from "../../../api/baseApi";
import RemoveLinkDialogWindow from "./remove-link-dialog-window/RemoveLinkDialogWindow";
import EdItLinkDialogWindow from "./update-link-dialog-window/EdItLinkDialogWindow";
import { toast } from "react-toastify";
import PlusCicrcle from "../../../assets/icons/PlusCicrcle";

const CustomerLinksPage = () => {
  const [isWindowShow, setIsWindowShow] = useState(false);
  const [removedLink, setRemovedLink] = useState<CustomerLink | null>(null);
  const [editingLink, setEditingLink] = useState<CustomerLink | null>(null);

  const [links, setLinks] = useState<CustomerLink[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const hideAndUpdate = (link: CustomerLink) => {
    setIsWindowShow(false);
    setLinks((prevState) => [...prevState, link]);
  };

  const updateLink = async (link: CustomerLink) => {
    try {
      await api(`customers-links/update?id=${link.id}`, {
        method: "PUT",
        data: { ...link },
        headers: {
          "secret-key": "7td-53f21d0m#om!vye*mrq7$w3f@e2p3#(4--*^p_#(zr+^rt",
        },
      });
      setEditingLink(null);
      await getLinks();
    } catch (error: any) {
      console.log(error);
    }
  };

  const deleteLink = async () => {
    try {
       await api(`customers-links/delete?id=${removedLink?.id}`, {
        method: "DELETE",
        headers: {
          "secret-key": "7td-53f21d0m#om!vye*mrq7$w3f@e2p3#(4--*^p_#(zr+^rt",
        },
      });
      await getLinks();
      setRemovedLink(null);
    } catch (error) {
      console.log(error);
    }
  };

  const copyLink = async (link: CustomerLink) => {
    await navigator.clipboard.writeText(link.current_url);
    toast.success("Ссылка скопирована", {
      position: "bottom-center",
      autoClose: 1500,
    });
  };

  const getLinks = async () => {
    const result = await api<CustomerLink[]>("customers-links", {
      method: "GET",
      headers: {
        "secret-key": "7td-53f21d0m#om!vye*mrq7$w3f@e2p3#(4--*^p_#(zr+^rt",
      },
    });

    setLinks(result);
  };

  useEffect(() => {
    getLinks();
    setIsLoading(false);
    // Bugsnag.notify(new Error("Test prod error"));
  }, []);

  const handlerUpdate = async () => {
    await getLinks();
  };

  return (
    <>
      {isWindowShow && (
        <LinksDialogWindow
          hideCb={() => setIsWindowShow(false)}
          hideAndUpdate={hideAndUpdate}
          handlerUpdate={handlerUpdate}
        />
      )}
      {removedLink && (
        <RemoveLinkDialogWindow
          hide={() => {
            setRemovedLink(null);
          }}
          removeLink={() => deleteLink()}
        />
      )}
      {editingLink && (
        <EdItLinkDialogWindow
          hide={() => setEditingLink(null)}
          updateLink={(link) => updateLink(link)}
          link={editingLink}
        />
      )}

      <ContentContainer>
        <CustomersInfoCard customPadding={"p-[30px]"}>
          <div className={"flex flex-col gap-5 overflow-y-auto"}>
            <button
              onClick={() => setIsWindowShow((prevState) => !prevState)}
              className={
                "bg-[#141515] border border-[#252627] w-[250px]  text-[#d7d7d7] transition-colors items-center rounded-md hover:bg-[#3d3e3f] gap-x-4  px-8 p-3 flex justify-between"
              }
            >
              Добавить линку
              <PlusCicrcle />
            </button>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <div className={"flex flex-col bg-[#141515]"}>
                <div className={"flex"}>
                  <p
                    className={"w-[40px] px-2 py-1  border border-[#252627]"}
                  ></p>
                  <p className={"w-[170px] px-2 py-1 border border-[#252627]"}>
                    Название
                  </p>
                  <p
                    className={
                      "w-[160px] px-2 py-1 border border-[#252627] text-center"
                    }
                  >
                    Номер телефона
                  </p>
                  <p
                    className={
                      "w-[140px] px-2 py-1 border border-[#252627] text-center"
                    }
                  >
                    Шаблон страницы
                  </p>
                  <p
                    className={
                      "w-[350px] px-2 py-1 border border-[#252627] text-center"
                    }
                  >
                    Редирект
                  </p>
                  <p
                    className={
                      "w-[40px] px-2 py-1 border border-[#252627] text-center"
                    }
                  ></p>
                  <p
                    className={
                      "w-[40px] px-2 py-1 border border-[#252627] text-center"
                    }
                  ></p>
                  <p
                    className={
                      "w-[80px] border border-[#252627] flex items-center justify-center"
                    }
                  >
                    Хитов
                  </p>
                  <p
                    className={
                      "w-[80px] border border-[#252627] flex items-center justify-center"
                    }
                  >
                    Успешно
                  </p>
                  <p
                    className={
                      "w-[40px] px-2 py-1 border border-[#252627] text-center"
                    }
                  ></p>
                </div>
                <LinksTable
                  links={links}
                  copyLink={(link) => copyLink(link)}
                  deleteLink={(link) => {
                    setRemovedLink(link);
                  }}
                  updateLink={(link) => {
                    setEditingLink(link);
                  }}
                />
              </div>
            )}
          </div>
        </CustomersInfoCard>
      </ContentContainer>
    </>
  );
};

export default CustomerLinksPage;
