import React, { useState, useEffect, useCallback, useRef } from "react";
import CancelIcon from "../assets/icons/CancelIcon";

function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

type SearchProps = {
  textPlaceholder?: string;
  onSearch: (query: string) => void;
  startValue: string;
};

const Search: React.FC<SearchProps> = ({
  textPlaceholder,
  onSearch,
  startValue,
}) => {
  const [query, setQuery] = useState(startValue);
  const debouncedQuery = useDebounce(query, 700);
  const isInitialLoad = useRef(true);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }

    if (debouncedQuery !== startValue) {
      onSearch(debouncedQuery);
    }
    //eslint-disable-next-line
  }, [debouncedQuery]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const handleClear = () => {
    setQuery("");
  };

  return (
    <div className="flex items-center w-full max-w-80 bg-[#141515] p-3 border border-gray-300 rounded-lg shadow-sm  ">
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder={textPlaceholder}
        className="flex-grow bg-transparent text-[#d7d7d7] placeholder:text-[#d7d7d7] outline-none"
        ref={inputRef}
      />
      {query && (
        <button
          onClick={handleClear}
          className="flex items-center px-3 cursor-pointer text-gray-400 outline-none hover:text-white"
        >
          <CancelIcon />
        </button>
      )}
    </div>
  );
};

export default Search;
