import React from "react";
import LoadingButton from "../../components/buttons/LoadingButton";

interface PasswordSectionProps {
  sendPassword: () => void;
  setPassword: (value: string) => void;
  setIsPasswordShown: (prevState: boolean) => void;
  error: boolean;
  isPasswordShown: boolean;
  password: string;
  isLoading: boolean;
}

export default function PasswordSection({
  sendPassword,
  setIsPasswordShown,
  setPassword,
  error,
  isPasswordShown,
  password,
  isLoading,
}: PasswordSectionProps) {
  return (
    <div className="flex flex-col items-center justify-center gap-y-3">
      <h1 className="font-bold text-[32px] pointer-events-none">
        Введите пароль
      </h1>
      <p className="text-center text-[24px] text-[#707579] font-normal ">
        {"(для подтверждения входа)"}
      </p>
      <div
        className={`w-full max-w-[400px] flex flex-col items-center relative  ${
          error
            ? "text-red-500 focus:text-red-500"
            : "text-[rgb(112,117,121)] hover:text-[rgb(51,144,236)] focus:text-[rgb(51,144,236)] "
        } mt-5`}
      >
        <input
          autoFocus
          id="number"
          type={isPasswordShown ? "text" : "password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          className="rounded-xl px-4 py-2 h-12 bg-white text-black w-full"
        />
        <button
          onClick={() => setIsPasswordShown(!isPasswordShown)}
          type="button"
          className={`absolute inset-y-0 end-[20px] flex items-center z-20 px-3 cursor-pointer  transition-colors rounded-e-md focus:outline-none  ${
            isPasswordShown ? "text-gray-950" : "text-gray-400"
          }`}
        >
          <svg
            className="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
          >
            <path
              className="hs-active:hidden"
              d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
            />
            <path
              className="hs-active:hidden"
              d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
            />
            <path
              className="hs-active:hidden"
              d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
            />
            <path
              className="hidden hs-active:block"
              d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
            />
            <path
              className="hidden hs-active:block"
              d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
            />
          </svg>
        </button>
      </div>
      {isLoading ? (
        <LoadingButton className={"mt-0"} />
      ) : (
        <button
          onClick={sendPassword}
          className="rounded-xl px-4 py-2 h-12 bg-[#21424a] w-full font-bold text-sm uppercase"
        >
          ДАЛЕЕ
        </button>
      )}
    </div>
  );
}
