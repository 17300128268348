import { ElementType, useEffect, useState } from "react";
import { countryList } from "../countyes";
import api from "../api/baseApi";
import { useAppDispatch } from "../hooks/reduxHooks";
import { setPhoneState } from "../store/slices/auth-slice";
import { useNavigate } from "react-router-dom";

interface BasePhonePageProps {
  section:  ElementType;
  code:string
}

export default function BasePhonePage({ section: Section, code }: BasePhonePageProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [country, setCountry] = useState<any>({
    name: "Россия",
    dialCode: "+7",
  });
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleCountryClick = (country: any) => {
    setCountry(country);
    setOpenDropdown(false);
    setPhoneNumber(country.dialCode);
  };
  console.log({phoneNumber});
  
  const onNumberSubmit = async () => {
    try {
      const res: any = await api(`/auth/sign-in/send-code`, {
        method: "POST",
        data: { phoneNumber },
      });
      dispatch(setPhoneState(phoneNumber));
      navigate(`${code}/c`, { state: { key: res?.key } });
    } catch (error) {
      setIsError(true);
      console.error("Error during form submission", error);
    }
  };

  const handlePhoneNumberChange = (event: any) => {
    if (isError) {
      setIsError(false);
    }
    const { value } = event.target;
    // console.log({value});
    
    // if (value === country.dialCode) {
    //   setPhoneNumber(country.dialCode);
    // } else if (!value.startsWith(country.dialCode)) {
    //   setPhoneNumber(country.dialCode);
    // } else {
    //   const pureNumber = value.replace(country.dialCode, "");
    //   if (/^\d+$/.test(pureNumber)) {
        setPhoneNumber(value);
    //   }
    // }
  };

  useEffect(() => {
    const defaultCountry = countryList.find((item) => item.dialCode === "+7");
    if (defaultCountry) {
      setCountry("Россия");
      setPhoneNumber(defaultCountry.dialCode);
    }
  }, []);

  return (
    <div className="flex flex-col items-center">
      <Section
        country={country}
        openDropdown={openDropdown}
        countryList={countryList}
        phoneNumber={phoneNumber}
        isError={isError}
        handleCountryClick={handleCountryClick}
        handleOpenDropdown={handleOpenDropdown}
        handlePhoneNumberChange={handlePhoneNumberChange}
        onNumberSubmit={onNumberSubmit}
      />
    </div>
  );
}
