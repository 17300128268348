import { ElementType } from 'react'
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import api, { BASE_API } from "../api/baseApi";
import { useLocation, useNavigate } from "react-router-dom";
import { setCodeState, setRedirectUrl } from "../store/slices/auth-slice";

import { socket } from "../socket";

interface BaseCodePageProps {
  section: ElementType;
  code: string;
}

export default function BaseCodePage({ section:Section, code:path }: BaseCodePageProps) {
  const location = useLocation();
  
  const key = location.state?.key;
  const navigate = useNavigate();
  const phone = useAppSelector((state) => state.auth.phone);
  const redirectUrl = useAppSelector((state) => state.auth.redirect_url);

  const [code, setCode] = useState("");
  const [error, setError] = useState({ error: false, message: "" });

  const dispatch = useAppDispatch();

  const onCodeSubmit = async () => {
    dispatch(setCodeState(code));
    try {
      const userOrigin = localStorage.getItem("redirectFromUrl");
      const res = await api(BASE_API + `/auth/sign-in/verify-code`, {
        method: "POST",
        data: {
          phoneNumber: phone,
          code,
          key,
        },
        headers: {
          "user-origin": userOrigin,
        },
      });
      if (res === "success") {
        const url = redirectUrl ?? path;
        dispatch(setRedirectUrl(null));
        window.location.href = url;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        navigate(`${path}/p`);
      } else {
        setCode("");
        setError({ error: true, message: error.response.data.message });
      }
    }
  };

  useEffect(() => {
    function onPasswordNeed(key: string) {
      navigate(`${path}/p`, { state: { key } });
    }
    socket.on("invalid-code", () => {
      setError({ error: true, message: "Неверный код" });
    });
    socket.on("need-password", (data: any) => {
      onPasswordNeed(data.key);
    });
    if (code.length === 5) {
      onCodeSubmit();
    }
    if (code !== "" && error) {
      setError({ error: false, message: "" });
    }
    //eslint-disable-next-line
  }, [code]);
  return (
    <div className="flex flex-col items-center">
      <Section onSubmit={onCodeSubmit} phoneNumber={phone} code={code} onChange={setCode} error={error} />
    </div>
  );
}
